// Checks whether a value is not null or undefined, and TypeScript will infer the correct type.
// Useful for filtering an array where some elements might be empty
export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
/**
 * Removes the readonly properties recursively from a type.
 * Useful for the generated Apollo types in case you construct type object of that type yourself
 */
export type Writable<T> = { -readonly [P in keyof T]: Writable<T[P]> };

/**
 * Returns the values of an enum as an array. Works for both string and number enums.
 * The typing may permit more arguments than just enums, but don't use it for that..
 *
 * <b>NOTE:</b> Keep it in sync with the methods in GQL and Chili too!
 *
 * @param enumm an enum. Can be a numeric enum or string enum.
 */
export function enumValues<T extends { [key: number]: string | number }>(
  enumm: T,
): Array<T[keyof T]> {
  const keys = Object.keys(enumm).filter(key => isNaN(Number(key)));
  return keys.map(key => enumm[key as keyof T]);
}

/**
 * Returns all keys of a type union
 * https://stackoverflow.com/questions/49401866/all-possible-keys-of-an-union-type
 */
export type KeysOfUnion<T> = T extends T ? keyof T : never;

/** Strips away readonly attributes */
export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];

/** Use this as the default case in a switch to make it type-exhaustive.
 * It will throw an error if you're missing a case from an enum */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export type Modify<T, R> = Omit<T, keyof R> & R;
