import { Box } from '@energiebespaarders/symbols';
import React from 'react';
import Slider from '~/components/Slider';
import { DEB_PARTNERS } from '~/utils/debPartners';
import { StyledPartnerLogo } from './Partners';

const PartnerSlider: React.FC = () => {
  return (
    <Box py={5} style={{ mixBlendMode: 'multiply' }}>
      <Slider
        arrows={false}
        autoplay
        autoplaySpeed={0}
        draggable={false}
        cssEase="linear"
        dots={false}
        infinite
        pauseOnFocus={false}
        pauseOnHover={false}
        slidesToScroll={1}
        speed={3000}
        touchThreshold={100}
        variableWidth
      >
        {DEB_PARTNERS.filter(partner => !partner.hide)
          .filter(partner => !partner.hide)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(partner => (
            <StyledPartnerLogo
              key={partner.key}
              src={partner.logo}
              alt={partner.name}
              style={{ width: partner.logoWidth }}
              $height="60px"
              $maxWidth="auto"
              $maxHeight="auto"
              px={3}
              loading="lazy"
            />
          ))}
      </Slider>
    </Box>
  );
};

export default PartnerSlider;
