import { Modal, Placeholder } from '@energiebespaarders/symbols';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import React, { useCallback, useEffect, useState } from 'react';
import { scrollSpy } from 'react-scroll';
import { useFlag } from 'react-unleash-flags';
import Benefits from '~/components/HomePage/Benefits';
import BlogSlider from '~/components/HomePage/BlogSlider';
import Hero from '~/components/HomePage/Hero';
import PartnerSlider from '~/components/HomePage/PartnerSlider';
import Process from '~/components/HomePage/Process';
import Products from '~/components/HomePage/Products';
import TestimonialSlider from '~/components/HomePage/TestimonialSlider';
import Truster from '~/components/HomePage/Truster';
import type {
  getHighlightedBlogPosts,
  getHighlightedBlogPosts_allBlogPosts,
} from '~/types/generated/cms/getHighlightedBlogPosts';
import { GET_HIGHLIGHTED_BLOG_POSTS } from 'src/datoCMS/queries';
import { getDatoClient } from 'src/datoCMS/datoCmsClient';
import type { GetStaticProps } from 'next';

// Dynamically load conditionally shown components to reduce bundle size
const Fallback = () => <Placeholder height="200px" />;
const WeAreHiring = dynamic(() => import('~/components/HomePage/WeAreHiring'), {
  loading: Fallback,
});

interface Props {
  blogPosts: readonly getHighlightedBlogPosts_allBlogPosts[];
}

const HomePage: React.FC<Props> = ({ blogPosts }) => {
  const [videoId, setVideoId] = useState('');
  useEffect(() => {
    scrollSpy.update();
  }, []);

  const showHiringFlag = useFlag('show-hiring');

  const openIntroVideo = useCallback(() => setVideoId('hsooloBKF4Q'), []);
  const closeVideoModal = useCallback(() => setVideoId(''), []);
  return (
    <>
      <Head>
        <link rel="canonical" href={'https://energiebespaarders.nl/'} key="meta-canonical" />
      </Head>
      <Hero openVideoModal={openIntroVideo} />

      <Truster />

      <Process />

      <TestimonialSlider />

      <PartnerSlider />

      <Products />

      <Benefits />

      {/* <Proposition /> */}

      <BlogSlider posts={blogPosts} />
      {/* <OrientationHelpCTA /> */}

      {showHiringFlag?.enabled && <WeAreHiring />}

      <Modal contentPadding={0} isOpen={!!videoId} onRequestClose={closeVideoModal} mobile={false}>
        <div className="embed-responsive embed-responsive--16x9">
          <iframe
            src={`https://www.youtube.com/embed/${videoId}?rel=0&amp;showinfo=0&amp;autoplay=1&amp;color=white`}
            allowFullScreen
            title={videoId}
          />
        </div>
      </Modal>
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const client = getDatoClient();

  const { data } = await client.query<getHighlightedBlogPosts>({
    query: GET_HIGHLIGHTED_BLOG_POSTS,
  });

  return {
    props: { blogPosts: data.allBlogPosts },
    revalidate: 3600,
  };
};

export default HomePage;
