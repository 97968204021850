import { Icon } from '@energiebespaarders/symbols';
import { ArrowLeft, ArrowRight } from '@energiebespaarders/symbols/icons/line';
import { padding } from '@energiebespaarders/symbols/styles/mixins';
import React, { useMemo } from 'react';
import { default as SlickSlider } from 'react-slick';
import styled from 'styled-components';
import type { CustomArrowProps, Settings } from 'react-slick';

const StyledSlider = styled(SlickSlider)`
  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: flex;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    ${padding(2, 'x')};
    ${padding(2, 'y')};

    & *:focus {
      outline: none;
    }
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
  }
  .slick-vertical .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
    z-index: 1;
  }
  .slick-next,
  .slick-prev {
    font-size: 0;
    line-height: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0;
  }
  .slick-next:focus,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0;

    &::before {
      opacity: 1;
    }
  }
  .slick-next.slick-disabled:before,
  .slick-prev.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: #fff;
  }
  .slick-prev {
    left: 0;
  }
  [dir='rtl'] .slick-prev {
    right: 0;
    left: auto;
  }
  .slick-next {
    right: 0;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: 0;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    width: 100%;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    &::before {
      color: ${x => x.theme.colors.gray};
    }

    &.slick-active {
      &::before {
        color: ${x => x.theme.colors.green};
      }
    }
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
  }
  .slick-dots li button:focus,
  .slick-dots li button:hover {
    outline: 0;
  }
  .slick-dots li button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '';
    text-align: center;
    opacity: 0.25;
    color: #000;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: #000;
  }
`;

const PrevArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <div style={props.style as any} {...props}>
    <Icon icon={ArrowLeft} solid fill="gray" hoverColor="green" width="100%" ml={-1} />
  </div>
);

const NextArrow = ({ currentSlide, slideCount, ...props }: CustomArrowProps) => (
  <div style={props.style as any} {...props}>
    <Icon icon={ArrowRight} solid fill="gray" hoverColor="green" width="100%" ml={1} />
  </div>
);

const Slider: React.FC<Settings> = ({ children, ...settings }) => {
  const sliderSettings = useMemo(
    () => ({
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      infinite: true,
      speed: 500,
      focusOnSelect: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
      ...settings,
    }),
    [settings],
  );
  return <StyledSlider {...sliderSettings}>{children}</StyledSlider>;
};

export default Slider;
